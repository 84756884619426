import React from "react"
import Layout from "../components/Layout"



const FeaturesPage = ({location }) => {
  return (
    <Layout location={location}>
      HELLO FROM FeaturesPage 
    </Layout>
  )
}

export default FeaturesPage
